.screenHolder {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: #000;
  .screen-canvas {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    &.CanvasA {
      background-color: #333;
    }
    &.CanvasB {
      background-color: #666;
    }
  }
  &.transitionKind-fade {
    .screen-canvas {
      transition: opacity 1s ease-in-out;
      opacity: 0;
      &.canvas-load {
        display: none;
        opacity: 0;
      }
      &.canvas-ready {
        opacity: 0;
        z-index: 2;
      }
      &.canvas-show {
        opacity: 1;
        z-index: 2;
      }
      &.canvas-hide {
        opacity: 1;
      }
    }
  }
  &.transitionKind-slide {
    .screen-canvas {
      transition: transform .8s ease-in-out, opacity .5s ease-in-out;
      opacity: 1;
      transform: translate3d(+100%, 0, 0);
      &.canvas-load {
        transform: translate3d(+100%, 0, 0);
        display: none;
        opacity: 0;
      }
      &.canvas-ready {
        transform: translate3d(+100%, 0, 0);
        // opacity: 0;
      }
      &.canvas-show {
        opacity: 1;
        transform: translate3d(0px, 0, 0);
        z-index: 2;
      }
      &.canvas-hide {
        transform: translate3d(-100%, 0, 0);
      }
    }
  }
  &.transitionKind-instant {
    .screen-canvas {
      opacity: 1;
      &.canvas-load {
        opacity: 0;
      }
      &.canvas-ready {
        opacity: 0;
      }
      &.canvas-show {
        opacity: 1;
        z-index: 2;
      }
      &.canvas-hide {
        opacity: 0;
      }
    }
  }
}