$height: 30px;
$transition: .6s all .1s ease-in-out;
$globalSmallSpacing: 8px;
$dropDownBackground: #ddd;
$dropDownBackgroundBasic: #fff;
$dropDownBackgroundHover: #f2f5f6;
.ptdropzoneHorizontal {
  width: 100%;
  min-height: 0px;
  max-height: 0px;
  position: absolute;
  transition: $transition;
  z-index: 5;
  background-color: transparent;// rgba(255, 200,75, .1);
  padding: 0;
  border: none;
  bottom: -$height * .5;
  left: 0;
  // background-color: #67983c;
  &.ptdropBottom {
    bottom: -$height * .5;
  }
  &.ptdropTop {
    top: -$height;
    padding-left: 70px;
  }
  &.ptdropTopGeneric {
    top: -$height * .5;
    bottom: auto;
    z-index: 1000;
  }
  &.ptdropBottomGeneric {
    bottom: -$height * .5;
    z-index: 2;
  }
  &.ptdzcanDrop {
    display: block;
    height: $height;
    max-height: 4 * $height;
    opacity: 1;
    padding: $globalSmallSpacing;
  }
  &.drop-loading {
    padding: $globalSmallSpacing;
    height: $height;
    max-height: 4 * $height;
    animation: gradientPulse 4s infinite;
  }
}

@keyframes gradientPulse {
  0% { background: linear-gradient(45deg, #fff, #000); }
  50% { background: linear-gradient(45deg, #000, #aaa); }
  100% { background: linear-gradient(45deg, #aaa, #fff); }
}


.ptdropzone {
  // position: absolute;
  z-index: 1;
  // padding: $globalSmallSpacing;
  &.ptdzcanDrop {
    min-height: 10px;
    background: repeating-linear-gradient(
      -45deg,
      $dropDownBackgroundBasic,
      $dropDownBackgroundBasic 5px,
      $dropDownBackground 5px,
      $dropDownBackground 10px
    );
    &.ptdzhover {
      background: repeating-linear-gradient(
      -45deg,
      $dropDownBackgroundBasic,
      $dropDownBackgroundBasic 5px,
      $dropDownBackgroundHover 5px,
      $dropDownBackgroundHover 10px
    );
    }
  }
}
