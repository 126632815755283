.checkBox {
  line-height: 20px;
  cursor: pointer;
  &::before {
    color: rgba(0, 0, 0, .1);
    content: '✓';
    line-height: 20px;
    border: 1px solid #000;
    width: 20px;
    height: 20px;
    display: inline-block;
    text-align: center;
    margin-right: 4px;
  }
  &:hover::before {
    color: #333;
    content: '✓';
  }
}

.checkBoxChecked {
  &::before {
    content: '✓';
    border: 1px solid #000;
    width: 20px;
    height: 20px;
  }
}

.deleteBox {
  line-height: 20px;
  cursor: pointer;
  &::before {
    content: '✖';
    color: rgba(0, 0, 0, .1);
    line-height: 20px;
    border: 1px solid #000;
    width: 20px;
    height: 20px;
    display: inline-block;
    text-align: center;
    margin-right: 4px;
  }
  &:hover::before {
    content: '✖';
    color: #333;
  }
}

.text-align-right {
  text-align: right;
}

.alert-box {
  border: 1px solid #a33;
  background-color: #faa;
  color: #600;
  padding: 14px;
  margin-top: 16px;
  margin-bottom: 16px;

}

.info-box {
  border: 1px solid #6f6;
  background-color: #afa;
  color: #060;
  padding: 14px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.page-header {
  text-align: right;
  a {
    margin: 2px;
  }
}

.w3-button.w3-green {
  &:hover {
    color: #000 !important;
    background-color: rgb(229, 250, 215) !important;
  }
}

.w3-button.w3-yellow {
  &:hover {
    color: #000 !important;
    background-color: rgb(249, 255, 128) !important;
  }
}

.pointer {
  cursor: pointer;
}

@media print {
  .bookingSuccess {
    color: #000 !important;
  }
  .dontPrint {
    display: none;
  }
}

html, body {
  height: 100%;
}

#root {
  height: 100%;
}

.root {
  height: 100%;
}

// Cleanup admin 2024-07-15
.menu-space-top {
  // padding-top: 50px;
}

.UGroup-main {
  max-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /*
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: #fff;
  .list-mode {
    flex-grow: 2;
  }
  */
}

.flexRows {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  // height: 100%;
  overflow: hidden;
  &> {
    max-height: 100%;
  }
}

.flexColumns {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  // width: 100%;
  overflow: hidden;
  &> {
    max-width: 100%;
    border-left: #eee;
    border-right: #eee;
  }
}

.flexGrow {
  flex-grow: 2;
}

.h100 {
  height: 100%;
  max-height: 100%;
  overflow: auto;
}

.h2Admin {
  margin: 0;
  padding: 16px;
  font-size: 16px;
}
/*
.menu-list {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
*/