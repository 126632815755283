.single, .list {
  transition: .2s transform ease-in-out;
}

.single-mode {
  
  &>.list {
    // display: none;
    transform: scale(0);
  }
}

.list-mode {
  &>.single {
    // display: none;
    transform: scale(0);
    position: absolute;
  }
}

.dragging {
  transition: .2s opacity ease-in-out;
  opacity: .1;
}