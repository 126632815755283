$tmsHeight: 4px;
$tmsHourHeight: $tmsHeight * 4;
.timeMatrixSlot {
  height: $tmsHeight;
  display: block;
  &:hover {
    border: 1px solid blue !important;
  }
}
.timeMatrixSlotHeader {
  text-align: center;
}
.timeMatrixSlotHourLabel {
  height: $tmsHourHeight;
  overflow: hidden;
  font-size: .8em;
  border-top: 1px solid #eee;
  text-align: center;
}
.timeMatrixSlotWidth {
  width: 12.5% !important;
}