.menu-list {
  margin-left: 0;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  li {
    list-style-type: none;
    display: inline-block;
  }
  .menu-item-active {
    background-color: blue;
    color: white;
  }
}