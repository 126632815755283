.admin-scroll-panel {
  overflow-y: auto;
}
.flex {
  display: flex;
  justify-content: space-between;
}
.flex-tarts {
  display: flex;
  justify-content: flex-start;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
}
.flex-grow {
  flex-grow: 2;
  text-align: left !important;
}
.h2Admin {
  background-color: #eee;
}