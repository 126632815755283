.normal-content {
  .snippedDisplay,
  .snippedDemo {
    padding: 1.5vw;
  }
} 
.snippedDisplay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  
  font-size: 3vw;
  h1 {
    font-size: 3.4vw;
  }
  h2 {
    font-size: 3.3vw;
  }
  h3 {
    font-size: 3.2vw;
  }
  h4 {
    font-size: 3.1vw;
  }
  
}

.snippedDemo, .jodit-workplace, .snippedDisplay {
  line-height: 1.4;
  h1, h2, h3, h4 {
    padding: 0;
    margin: 0;
  }
  table {
    width: 100%;
  }
}

.snippedDemo, .jodit-workplace {
  height: 768px;
  width: 1024px;
  padding: 15px;
  font-size: 30px;
  h1 {
    font-size: 34px;
  }
  h2 {
    font-size: 33px;
  }
  h3 {
    font-size: 32px;
  }
  h4 {
    font-size: 31px;
  }
}

.snippedPreview {
  width: 300px;
  height: 169px;
  position: relative;
  overflow: hidden;

  .snippedThumb {
    width: 500% !important;
    height: 500% !important;
    transform: scale(0.2) !important;
    transform-origin: 0 0 !important;
  }

}
