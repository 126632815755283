.single-top .qrCode {
  // float: right;
  // height: 120px;
}
.qrCodeParent {
  min-height: 100px;
}

.positionRelative {
  position: relative;
}

.newRuleDropZone {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  opacity: .5;
  display: flex;
  align-items: center;
  justify-content: center;
}
